import { usePostHog } from 'posthog-js/react';
import { appCache } from '../utils/cache';
import { useEffect, useState } from 'react';

export const TRACK_EVENT_NAMES = {
  CartProductAdded: 'Carrito - Producto agregado',
  CartView: 'Carrito - Visto',
  CartProductsCompleted: 'Carrito - Productos seleccionados',
  CartDeliveryCompleted: 'Carrito - Datos de entrega confirmados',
  CartDeliveryLocationEditRequested: 'Carrito - Editar direccion de entrega',
  CartDeliveryLocationEdited: 'Carrito - Direccion de entrega modificada',
  CartOrderCompleted: 'Carrito - Pedido exitoso',
  CartPaymentRequested: 'Carrito - Pago solicitado',
  CartPaymentFailed: 'Carrito - Pago fallido',
  MenuNotificationLoaded: 'Menu - Notificacion cargada',
  MenuCommerceLoaded: 'Menu - Datos del comercio cargado',
  MenuNotFound: 'Menu - No encontrado',
  MenuProductView: 'Menu - Producto visto',
  DeliveryRiderAssigned: 'Reparto - Repartidor asignado',
  OrdersLoaded: 'Pedido - Pedidos cargados',
  ThreeDSecureFailed: 'Pago - 3D Secure fallido',
};

const getCustomerData = () => {
  const phoneNumber = appCache.phoneNumber;

  const customerData = {
    phoneNumber: phoneNumber,
  };
  if (phoneNumber) {
    customerData.distinct_id = phoneNumber;
    customerData.client_phone = phoneNumber;
  }

  return customerData;
};

const useTracking = () => {
  const posthog = usePostHog();
  const [commerce, setCommerce] = useState();

  useEffect(() => {
    posthog.capture('$pageview');

    return () => {
      posthog.capture('$pageleave');
    };
  }, []);

  const setTrackingCommerce = (idPath) => {
    setCommerce(idPath);
    posthog?.group('commerce', idPath);
  };

  const trackEvent = (eventName, eventData) => {
    const customerData = getCustomerData();
    const data = {
      commerce: commerce,
      ...customerData,
      ...eventData,
    };

    posthog.capture(eventName, data);
  };

  return {
    setTrackingCommerce: setTrackingCommerce,
    trackEvent: trackEvent,
  };
};

export default useTracking;
