import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ApiServices from 'ApiServices/ApiServices';
import logo from 'Images/logo_with_slogan.svg';
import Page404 from 'Pages/Page404';
import BranchOfficesList from 'Components/directory/BranchOfficesList.jsx';
import MultivendorList from 'Components/directory/MultivendorList';

const Directory = ({ directoryPathName }) => {
  const [branchOfficesData, setBranchOfficesData] = useState({ comercios: [], directorio: {} });
  const [isMultivendor, setIsMultivendor] = useState('');
  const MultivendorType = 'multivendor';
  const [isLoading, setLoading] = useState(true);
  const [isNotFount, setNotFound] = useState(false);
  const loadingPageRef = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const brachOfficeResponse = await ApiServices.getBranchOfficesData(directoryPathName);
        if (brachOfficeResponse && brachOfficeResponse.directorio) {
          const hasMultivendorType = brachOfficeResponse.directorio.directory_type === MultivendorType;

          setBranchOfficesData(brachOfficeResponse);
          setIsMultivendor(hasMultivendorType);
        } else {
          setNotFound(true);
        }
      } catch (error) {
        setNotFound(true);
      }
      loadingPageRef.current.style.opacity = 0;
      loadingPageRef.current.addEventListener(
        'transitionend',
        () => {
          setLoading(false);
        },
        { once: true },
      );
    })();
  }, []);

  return (
    <>
      {isLoading ? (
        <div ref={loadingPageRef} className="loading_page">
          <img src={logo} alt="Plick Logo" />
        </div>
      ) : isNotFount ? (
        <Page404 />
      ) : isMultivendor ? (
        <MultivendorList directory={branchOfficesData.directorio} commerces={branchOfficesData.comercios} />
      ) : branchOfficesData.directorio && branchOfficesData.comercios.length > 0 ? (
        <BranchOfficesList pathName={directoryPathName} />
      ) : (
        <Page404 />
      )}
    </>
  );
};

export default Directory;

Directory.propTypes = {
  directoryPathName: PropTypes.string.isRequired,
};
